import React, { useState } from "react";
import "../../App.css";
import NameButton from "./NameButton";

const ButtonTable = ({
	names,
	selectedItems,
	setSelectedItems,
	allPlayersHaveVoted,
	voteSubmitted,
	correctNames,
}) => {
	const buttonContainerStyle = {
		display: "grid",
		gridTemplateColumns: "repeat(2, 1fr)", // Two columns per row
		gridGap: "10px", // Adjust the spacing between buttons as needed
		width: "100%",
		borderRadius: "10px",
		paddingTop: "20px",
		paddingBottom: "20px",
	};

	const lastButtonStyle = {
		gridColumn: "span 2", // Make the last button span both columns
		// transition: "background-color 0.1s",
	};

	const handleCheckboxChange = (name) => {
		if (selectedItems.includes(name)) {
			setSelectedItems(selectedItems.filter((item) => item !== name));
		} else {
			setSelectedItems([...selectedItems, name]);
		}
	};

	// Add a flag to check if the current name is the last one
	return (
		<div style={buttonContainerStyle}>
			{names.map((name, index) => (
				<NameButton
					key={index}
					correctNames={correctNames}
					name={name}
					allPlayersHaveVoted={allPlayersHaveVoted}
					voteSubmitted={voteSubmitted}
					handleChange={handleCheckboxChange}
					isChecked={selectedItems.includes(name)}
					// Apply lastButtonStyle to the last button
					style={
						(names.length > 0 &&
							names.length % 2 == 0 &&
							names[names.length - 1] === process.env.REACT_APP_AD_NAME &&
							index >= names.length - 2) ||
						(names.length % 2 === 1 && index === names.length - 1)
							? lastButtonStyle
							: {}
					}
				/>
			))}
		</div>
	);
};

export default ButtonTable;
