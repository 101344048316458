export default function GamemodeSwitcher({
	modes,
	selectedMode,
	setSelectedMode,
}) {
	return (
		<div
			style={{
				zIndex: "2",
				// maxWidth: "450px",
				paddingTop: "env(safe-area-inset-top)",
				width: "100%",
				// position: "absolute",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				overflow: "hidden",
				background: "#282828",
				opacity: "0.98",
			}}
		>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					width: "110%",
					// background: "red",
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						color: "white",
						width: "100%",
						// background: "red",
						justifyContent: "center",
						transform: "skew(-20deg)",
					}}
				>
					{modes.map((mode) => (
						<a
							id={mode}
							href={"/" + mode}
							key={mode}
							// onClick={() => setSelectedMode(mode)}
							style={{
								color: mode === selectedMode ? "white" : "lightgray",
								textDecoration: "none",
								flex: "1",
								textAlign: "center",
								background: mode === selectedMode ? "#3b3b3b" : "#282828",
								padding: "15px",
								borderRadius: "5px",

								// height: "0px",
							}}
						>
							<div style={{ transform: "skew(20deg)" }}>
								{mode === "asongus" ? "Asongus" : "Charts Game"}
							</div>
						</a>
					))}
				</div>
			</div>
		</div>
	);
}
