import React, { useEffect, useState } from "react";

const TimerBar = ({ duration = 5000, uri }) => {
	const [key, setKey] = useState(0); // To force animation restart

	// Restart the timer whenever `uri` changes
	useEffect(() => {
		setKey((prev) => prev + 1);
	}, [uri]);

	// Styles for the container and progress bar
	const containerStyle = {
		// position: "",
		width: "95%",
		height: "8px",
		backgroundColor: "#3B3B3B",
		borderRadius: "10px",
		overflow: "hidden",
		boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
	};

	const barStyle = {
		height: "100%",
		backgroundColor: "lightgray",
		width: "100%", // Bar expands fully
		animation: `progressAnimation ${duration}ms linear forwards`,
		key: key, // Force re-render of the animation
	};

	return (
		<div style={containerStyle}>
			<div style={barStyle} />
		</div>
	);
};

export default TimerBar;
