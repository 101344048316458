import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../App.css";
import SongDisplayPanel from "./SongDisplayPanel";
import ButtonTable from "./ButtonTable";
import SpotifyPlayer from "../../components/SpotifyPlayer";
import TimerBar from "../../components/TimerBar";

const GuessSongScreen = ({
	song,
	artist,
	image,
	uri,
	names,
	socket,
	handleSubmit,
	transitionOut,
	blindMode,
}) => {
	const { lobbyId } = useParams();
	const [selectedItems, setSelectedItems] = useState([]);
	const [voteSubmitted, setVoteSubmitted] = useState(false);
	const [correctNames, setCorrectNames] = useState(null);
	const [timeLeft, setTimeLeft] = useState(0); // Add state to manage timeLeft
	const [startTime, setStartTime] = useState(null); // Manage start time for TimerBar

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_URL}/getTimeLeft/${lobbyId}`,
					{
						method: "GET",
					}
				);

				const data = await response.json();
				console.log("Response from server:", data);

				if (data.error) {
					console.error("Error from server:", data.error);
				} else {
					console.log("Time left:", data.timeLeft);
					setTimeLeft(data.timeLeft); // Update state with time left
					setStartTime(Date.now()); // Record the start time
				}
			} catch (error) {
				console.error("Error:", error);
			}
		};

		fetchData();
	}, [lobbyId]);

	useEffect(() => {
		socket.emit("getPreviousVoteIfThereIsOne", lobbyId);
	}, [socket]);

	useEffect(() => {
		socket.on("sendLastGuess", (guess) => {
			setSelectedItems(guess);
			setVoteSubmitted(true);
			// console.log(guess);
		});
		socket.on("guessReceived", () => {
			setVoteSubmitted(true);
		});

		socket.on("correctAnswers", (answers) => {
			setCorrectNames(answers);
			console.log("Selected Items:", selectedItems);
			// setVoteSubmitted(true);
		});
	}, [selectedItems, socket]);

	// todo load has guessed state
	// prevent multiple guessing with refresh
	// make the server better

	// Determine whether the button should be disabled
	const isButtonDisabled = selectedItems.length === 0 || voteSubmitted;

	// Apply a grayed-out style to the button when it's disabled
	const buttonStyle = {
		width: "50%",
		// marginTop: '10px',
		minHeight: "50px",
		background: !isButtonDisabled ? "lightgray" : "#222222",
		color: "#222222",
		borderWidth: "0px",
		borderRadius: "10px",
		fontWeight: "bold",
		opacity: !isButtonDisabled ? "1" : "0",
	};

	//TODO GRADIENT FOR THE BUTTONS. or blur image background??

	const [imageLoaded, setImageLoaded] = useState(false);

	useEffect(() => {
		const img = new Image();
		img.src = image;
		img.onload = () => setImageLoaded(true);
	}, [image]);

	return (
		<>
			{imageLoaded && (
				<div style={{ position: "relative", zIndex: "0" }}>
					<div
						style={{ position: "relative", zIndex: "0" }}
						className={transitionOut ? "slide-up-out" : "slide-down"}
					>
						<SongDisplayPanel
							uri={uri}
							song={song}
							artist={artist}
							image={image}
							selectedItems={selectedItems}
							handleSubmit={() => handleSubmit(selectedItems)}
							voteSubmitted={voteSubmitted}
							blindMode={blindMode}
							correctNames={correctNames}
						/>
					</div>
					<div
						style={{ position: "relative", zIndex: "0" }}
						className={transitionOut ? "slide-down-out" : "slide-in"}
						// className="slide-in"
					>
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								marginTop: "20px",
								// position: "fixed",
								// top: "20px",
								// left: "20px",
							}}
						>
							<TimerBar duration={timeLeft} uri={uri} />
						</div>
						<ButtonTable
							correctNames={correctNames}
							voteSubmitted={voteSubmitted}
							names={names}
							selectedItems={selectedItems}
							setSelectedItems={setSelectedItems}
						/>
					</div>
				</div>
			)}
		</>
	);
};

export default GuessSongScreen;
