import React, { useState } from "react";

const ContactButton = () => {
	const [copied, setCopied] = useState(false);

	return (
		<div>
			<a
				target="_blank"
				href="mailto:zspmailbox@gmail.com?subject=Asongus%20Inquiry"
				style={{
					textDecoration: "none",
					color: "gray",
					border: "none",
					background: "none",
					fontSize: "14px",
					cursor: "pointer",
				}}
			>
				Contact Me
			</a>
			{copied && (
				<div style={{ background: "white", color: "black", position: "fixed" }}>
					Game link copied!
				</div>
			)}
		</div>
	);
};

export default ContactButton;
