import React, { useState, useRef } from "react";
import styles from "../../styles/MainMenu.module.css";
import Loader from "../../components/Loader";
import PopUp from "./PopUp";

//check if its a spotify same for everyone playlist, prompt
//37i9dQZF1EI

const PlaylistSubmitComponent = ({ submitToServer, popUpWithMessage }) => {
	const [playlistLink, setPlaylistLink] = useState(
		/*window.localStorage.getItem("playlist") || */ ""
	);
	const [shake, setShake] = useState(false);
	const [awaitingResponse, setAwaitingResponse] = useState(false);

	const inputRef = useRef(null);

	const extractPlaylistId = (link) => {
		return link.split("playlist/")[1].split("?")[0];
	};

	function isValidApplePlaylistLink(link) {
		let split = link.split("pl.");
		return split.length >= 2;
	}

	const handleSubmit = async (e) => {
		if (!awaitingResponse) {
			e.preventDefault();
			window.localStorage.setItem("playlist", playlistLink);

			let playlist_id;
			let isApple = false;
			try {
				if (isValidApplePlaylistLink(playlistLink)) {
					playlist_id = playlistLink;
					isApple = true;
				} else {
					playlist_id = extractPlaylistId(playlistLink);
				}
			} catch {
				setAwaitingResponse(false);
				popUpWithMessage("Invalid playlist link");
				setShake(true);
				setTimeout(() => {
					setShake(false);
				}, 200);
				return;
			}

			if (
				playlist_id === "37i9dQZF1EP6YuccBxUcC1" ||
				playlist_id.startsWith("37i9dQZF1D") ||
				playlist_id.startsWith("37i9dQZF1EI") ||
				playlist_id.startsWith("37i9dQZF1EQ") ||
				playlist_id.startsWith("37i9dQZF1EV")
			) {
				popUpWithMessage(
					'Auto-Generated Playlist Detected: Click "Add to other playlist" and submit the new playlist link for it to work.',
					"spotify:playlist:" + playlist_id,
					"Okay"
				);

				return;
			}
			try {
				setAwaitingResponse(true);
				await submitToServer(playlist_id, isApple);
				setAwaitingResponse(false);
			} catch (error) {
				setAwaitingResponse(false);
				if (
					error.message === "Playlist Too Long. Choose one with < 2000 songs"
				) {
					popUpWithMessage("Error: " + error.message);
				} else {
					popUpWithMessage(
						"Error: Failed to connect to server." + error.message
					);
				}
				console.error("Error:", error);
				setShake(true);
				setTimeout(() => {
					setShake(false);
				}, 200);
			}
		} else {
		}
	};

	const [scrollPosition, setScrollPosition] = useState(0);

	const handleFocus = () => {
		// e.preventDefault();
		setScrollPosition(window.scrollY);
		if (inputRef.current) {
			inputRef.current.select();
		}
	};

	const handleBlur = () => {
		window.scrollTo({
			top: scrollPosition,
			behavior: "smooth",
		});
	};

	return (
		<>
			{/* <PopUp isOpen={true} content={<p>{"aids"}</p>} onClose={() => {}} /> */}
			<form
				onSubmit={handleSubmit}
				style={{
					display: "flex",
					width: "100%",
					flexDirection: "row",
					marginTop: "15px",
					marginBottom: "10px",
				}}
			>
				<input
					ref={inputRef}
					className={`input-placeholder ${shake ? styles.shake : ""} `}
					style={{
						height: "35px",
						flex: "5",
						borderRadius: "10px",
						background: "#474747",
						fontSize: "15px",
						marginRight: "10px",
						paddingLeft: "15px",
						border: "0px",
						color: "white",
						// touchAction:
					}}
					type="text"
					value={playlistLink}
					onChange={(e) => setPlaylistLink(e.target.value)}
					onFocus={handleFocus}
					onBlur={handleBlur}
					placeholder="Enter Spotify or Apple playlist link"
				/>

				<button
					style={{
						flex: "1",
						width: "50%",
						fontSize: "15px",
						fontWeight: "600",
						background: awaitingResponse || playlistLink ? "white" : "gray",
						color: "#111111",
						border: "0px",
						borderRadius: "5px",
						cursor: playlistLink ? "pointer" : "not-allowed",
					}}
					type="submit"
					disabled={awaitingResponse || !playlistLink}
				>
					{awaitingResponse ? <Loader /> : "Submit"}
				</button>
			</form>
		</>
	);
};

export default PlaylistSubmitComponent;
